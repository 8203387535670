// 안재현

// 인프라 대시보드 - No data
.fixed_height {
    height: 320px;
}
.charger_info {
    position: relative;
    height: 100%;

    .no-data {
        img {
            display: block;
            margin: auto;
            margin-bottom: 24px;
        }

        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: absolute;
        text-align: center;
        color: #73829f;
        font-size: 20px;
        font-weight: 500;
    }
}

// 회원 > 회원 등록/수정 결제카드 수정
.credit_card_modify_btn {
    background: var(--skyBlue);
    margin: 0px 0px 20px 0px;
    height: 36px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'pretendard-medium';
}
