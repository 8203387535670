@import './mixin';
/*===========================================================================
	FONT
============================================================================*/

@font-face {
    font-family: 'pretendard-light';
    src: url('../static/font/Pretendard-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'pretendard-semibold';
    src: url('../static/font/Pretendard-SemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'pretendard-regular';
    src: url('../static/font/Pretendard-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'pretendard-medium';
    src: url('../static/font/Pretendard-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'pretendard-bold';
    src: url('../static/font/Pretendard-Bold.woff2') format('woff2');
}

/*===========================================================================
	RESET
============================================================================*/
* {
    font-size: 14px;
    -webkit-tap-highlight-color: transparent;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input,
br {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    color: #fff;
    line-height: 1.2;
    letter-spacing: -0.36px;
    font-family: 'pretendard-regular';
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
a:link,
a:visited {
    text-decoration: none;
}
a:hover,
a:active {
    text-decoration: none;
}
img {
    border: none;
    vertical-align: middle;
}
ol,
ul,
li {
    list-style: none;
}
i,
em {
    font-style: normal;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
fieldset {
    border: none;
}
legend {
    width: 1px;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
    line-height: 0;
    font-size: 0;
    position: absolute;
    left: -999px;
}
button,
input[type='button'] {
    padding: 0;
    margin: 0;
    border: none;
    vertical-align: middle;
    cursor: pointer;
    background: transparent;
}
input {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
}
// input[type="text"]::-ms-clear {
//   display: none;
// }
// input[type="password"]::-ms-clear {
//   display: none;
// }
textarea {
    resize: none;
}
// input,
select,
button,
textarea {
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
button:focus {
    outline: 0;
}

sub {
    vertical-align: middle;
    font-size: 13px;
    font-family: inherit;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
} //스크롤바 전체
*::-webkit-scrollbar-thumb {
    background: #485771;
    border-radius: 50px;
} //스크롤 막대
*::-webkit-scrollbar-track {
} //스크롤 막대 외부

span {
    &.required:after {
        content: '*';
        display: inline-block;
        @include font(16, m);
        color: var(--yellow);
    }
}

/*reset end*/

//포커스시 플레이스 홀더 감추기
// input:focus {
//   &::-webkit-input-placeholder {
//     color: transparent;
//   }

//   &::-moz-placeholder {
//     color: transparent;
//   }

//   &:-ms-input-placeholder {
//     color: transparent;
//   }

//   &::-ms-input-placeholder {
//     color: transparent;
//   }
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 40px 40px 40px 40px #2d3749 inset !important; //배경색
    -webkit-text-fill-color: #fff !important; //글꼴 컬러
    caret-color: #fff; //커서 색
    border: none;
}

// 웹에디터 볼드
.textarea_wrapper {
    font-size: inherit;
    font-weight: inherit;

    em {
        font-style: italic;
    }
    strong {
        font-family: 'pretendard-bold';
    }
}
