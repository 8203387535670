/*===========================================================================
	'폰트'
============================================================================*/
@mixin ff_b {
    font-family: 'pretendard-bold';
}
@mixin ff_m {
    font-family: 'pretendard-medium';
}
@mixin ff_sm {
    font-family: 'pretendard-semibold';
}
@mixin ff_r {
    font-family: 'pretendard-regular';
}
@mixin ff_l {
    font-family: 'pretendard-light';
}

@mixin font($size, $weight, $scale: null) {
    @if ($scale) {
        font-size: #{$size * $scale}px;
    } @else {
        font-size: $size + px;
    }

    @if $weight == 'b' {
        font-family: 'pretendard-bold';
    }
    @if $weight == 'm' {
        font-family: 'pretendard-medium';
    }
    @if $weight == 'sm' {
        font-family: 'pretendard-semibold';
    }
    @if $weight == 'r' {
        font-family: 'pretendard-regular';
    }
    @if $weight == 'l' {
        font-family: 'pretendard-light';
    }
}

/*===========================================================================
	드래그 금지
============================================================================*/
@mixin no_drag_default {
    user-select: none;
}

@mixin no_drag_point {
    user-select: none;
    cursor: pointer;
}

/*===========================================================================
	카드 공통
============================================================================*/
@mixin card_form {
    background-color: var(--cardBackground);
    border-radius: 8px;
}
