#redirect {
    header {
        height: 68px;
    }
    main {
        height: calc(100vh - 231px);
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 58px;
            margin-bottom: 18px;
        }

        h2 {
            color: #73829f;
            font-size: 64px;
            font-weight: 600;
            line-height: 77px;
        }

        span {
            color: #3a475c;
            font-size: 18px;
        }

        p {
            color: #73829f;
            font-size: 18px;
            font-weight: 300;
            line-height: 30px;
            margin: 40px 0 60px;
            text-align: center;
        }

        div {
            display: flex;
            gap: 24px;
            width: 424px;

            button {
                flex: 1;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 500;
                height: 46px;
                line-height: 46px;
                text-align: center;
                white-space: nowrap;
                cursor: pointer;

                &:first-child {
                    border: 1px solid #00b0f0;
                    color: #00b0f0;
                }

                &:last-child {
                    background-color: #00b0f0;
                    color: #fff;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        main {
            height: unset;
            padding: 80px 0 90px;

            img {
                width: 45px;
                margin-bottom: 19px;
            }

            h2 {
                font-size: 44px;
                line-height: 56px;
            }

            span {
                font-size: 14px;
            }

            p {
                font-size: 14px;
                line-height: 24px;
            }

            div {
                width: 100%;
                padding: 0 15px;
                gap: 20px;

                button {
                    height: 44px;
                    line-height: 44px;
                }
            }
        }
    }
}
