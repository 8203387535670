@import './mixin';

:root {
    --blue: #0070bf;
    --skyBlue: #04aff0;
    --teal: #1ab1b7;
    --green: #04b050;
    --lightGreen: #92d051;

    --ashGreen: #c5e0b4;
    --yellow: #fec100;
    --delete: #ef476f;

    --title: #a6b7db;
    --sub: #73829d;

    --background: #2d3748;
    --mainBakcground: #1d1e28;
    --cardBackground: #27303f;
    --cardBorder: #333c4f;
    --selectBorder: #475671;

    height: 100%;
}

body {
    background: var(--mainBakcground);
    overflow: overlay;
    overflow-x: hidden;
    height: 100%;
}

#root {
    height: 100%;
}

#components {
    padding: 20px;
    // background: var(--mainBakcground);
    min-height: 100vh;

    > div {
        padding: 20px 0;
    }
}

/*===========================================================================
	FONT
============================================================================*/
.ff_b {
    font-family: 'pretendard-bold';
}

.ff_m {
    font-family: 'pretendard-medium';
}

.ff_r {
    font-family: 'pretendard-regular';
}

.ff_sm {
    font-family: 'pretendard-semibold';
}

.ff_l {
    font-family: 'pretendard-light';
}

/*===========================================================================
	COLOR
============================================================================*/
.color_white {
    color: #fff;
}

.color_blue {
    color: var(--blue);
}

.color_skyBlue {
    color: var(--skyBlue);
}

.color_teal {
    color: var(--teal);
}

.color_green {
    color: var(--green);
}

.color_lightGreen {
    color: var(--lightGreen);
}

.color_ashGreen {
    color: var(--ashGreen);
}

.color_yellow {
    color: var(--yellow);
}

.color_delete {
    color: var(--delete);
}

.color_title {
    color: var(--title);
}

.color_sub {
    color: var(--sub);
}

/*===========================================================================
	BACKGROUND
============================================================================*/
.bg_blue {
    background: var(--blue);
}

.bg_skyblue {
    background: var(--skyBlue);
}

.bg_teal {
    background: var(--teal);
}

.bg_green {
    background: var(--green);
}

.bg_lightGreen {
    background: var(--lightGreen);
}

.bg_yellow {
    background: var(--yellow);
}

/*===========================================================================
	position
============================================================================*/

.rel {
    position: relative;
}

.abs {
    position: relative;
}

/*===========================================================================
	text_align
============================================================================*/

.clearfix2 {
    content: '';
    display: block;
    clear: both;
}

.clearfix2 {
    & > * {
        float: left;

        &:nth-child(even) {
            float: right;
        }
    }
}

.ta_c {
    text-align: center;
}

.ta_l {
    text-align: left;
}

.ta_r {
    text-align: right;
}

.mini_alert_right {
    float: right;
    font-size: 13px;
    color: #73849e;
    margin-top: 6px;
}

.calendar_input {
    position: relative;

    img {
        position: absolute;
        top: calc(50% + 13px);
        transform: translateY(-50%);
        right: 14px;
    }
}

/*===========================================================================
	ellipsis
============================================================================*/

.ellipsis {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*===========================================================================
	cursor
============================================================================*/

.cs_p {
    cursor: pointer;
}

.cs_d {
    cursor: default;
}

/*===========================================================================
	user-select
============================================================================*/
.us_n {
    user-select: none;
}

/*===========================================================================
  Raduis
============================================================================*/
.circle {
    border-radius: 50%;
}

/*===========================================================================
LAYOUT
============================================================================*/
.w100 {
    width: 100%;
}

main {
    padding: 24px 30px;
    min-height: calc(100% - 337px);

    .main_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 40px;
        margin-bottom: 14px;

        .header_btn {
            button {
                width: 180px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                border-radius: 8px;
                font-size: 16px;
                color: var(--title);
                background: var(--cardBackground);
                @include no_drag_point;

                &:nth-of-type(2) {
                    margin-left: 20px;
                }
            }
        }
    }

    .main_contents {
        .map_status,
        .search_list_component_card,
        .station_chart_card,
        .status_card,
        .hourly_charging_chart,
        .hourly_rate_chart_info,
        .operation_card_dashboard,
        .report_select_card,
        .report_table,
        .compare_group {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    main {
        min-height: calc(100% - 329px);
    }
}

@media screen and (max-width: 768px) {
    main {
        padding: 20px 0px 0;
        min-height: calc(100% - 310px);

        .main_header {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            margin-bottom: 20px;

            .header_btn {
                padding: 0 15px;

                button {
                    width: 160px;
                    color: var(--title);
                    // margin-top: 20px;
                }
            }
        }
    }

    .mini_alert_right {
        display: block;
        float: none;
        margin-top: 12px;
    }
}

/*===========================================================================
	button
============================================================================*/

.btn {
    display: inline-block;
    width: 100%;
    height: 36px;
    border-radius: 8px;
    @include font(16, m);

    &.skyBlue {
        background: var(--skyBlue);
    }

    &.initial {
        background: var(--cardBackground);
        border: 1px solid var(--cardBorder);
    }

    &.gray {
        background: #a6a6a6;
        border: 1px solid var(--cardBorder);
    }

    &.lightGreen {
        background: var(--lightGreen);
        border: 1px solid var(--cardBorder);
    }

    &.yellow {
        background: var(--yellow);
        border: 1px solid var(--yellow);
    }

    &.gray2 {
        color: var(--title);
        background: var(--background);
        border: 1px solid var(--cardBorder);
    }

    &.mini {
        max-width: 100%;
        width: auto;
        padding: 0 30px;
        font-size: 14px;
        font-weight: 500;
    }
}

.right_btn {
    height: 32px;
    border-radius: 8px;
    background-color: #00b0f0;
    color: #fff;
    font-size: 14px;
    margin-left: 16px;
    padding: 0 16px;
}

.right_btn_wrap {
    &::after {
        content: '';
        display: block;
        clear: both;
    }

    margin-top: 24px;

    & > .btn {
        float: right;
    }

    .btn + .btn {
        margin-right: 10px;
    }
}

/*===========================================================================
	Table
============================================================================*/
.table_fixed {
    table-layout: fixed;
}

.half_table {
    margin-top: 40px;
    padding-top: 40px;
    position: relative;

    &.devide {
        &::before {
            content: '';
            display: block;
            width: calc(100% + 40px);
            height: 2px;
            background-color: #2c3949;
            position: absolute;
            top: 0;
            left: -20px;
        }
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    & > *:first-child {
        float: left;
    }

    & > *:last-child {
        float: right;
    }

    .card_table_wrapper {
        width: calc(50% - 20px);

        .card_table_wrap {
            table {
                &.card_table {
                    min-width: auto;
                }
            }
        }
    }
}

.card_table_wrapper {
    .card_table_wrap {
        overflow-x: auto;
        overflow-y: auto;

        &.overflow_visible {
            overflow: visible;
        }

        .billing_table {
            td {
                padding: 0 5px;
                white-space: nowrap;
            }

            th {
                padding: 0 5px;
                white-space: nowrap;
            }
        }

        //스크롤 클래스
        table.card_table {
            width: 100%;
            min-width: 768px;

            thead {
                background-color: #2d3749;
                height: 40px;
                position: sticky;
                top: 0;

                tr {
                    user-select: none;
                    border-bottom: 1px solid #46576f;
                    height: 40px;

                    th {
                        user-select: none;
                        font-weight: initial;
                        color: var(--sub);
                        @include font(14, m);

                        > span {
                            color: inherit;
                            font-family: inherit;
                            font-size: inherit;
                        }

                        @include font(14, m);

                        &:first-of-type {
                            padding-left: 20px;
                        }

                        &:last-of-type {
                            padding-right: 20px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    height: 40px;
                    border-bottom: 1px solid #2c3949;
                    user-select: none;

                    &.focus {
                        background-color: #3a485c;
                    }

                    &.gray {
                        color: var(--sub);

                        td {
                            color: inherit;

                            > span {
                                color: inherit;
                            }
                        }
                    }

                    td {
                        @include font(14, m);

                        > span {
                            font-size: inherit;
                            font-family: inherit;
                        }

                        &:first-of-type {
                            padding-left: 20px;
                        }

                        &:last-of-type {
                            padding-right: 20px;
                        }

                        .time_table {
                            padding: 20px 0;
                        }

                        .time_table_btn {
                            span {
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }

            &.detail_table {
                border: 1px solid #3a475c;
                min-width: 100%;

                // border-right: 1px solid #3a475c;
                & + table {
                    margin-top: 24px;
                }

                tr {
                    height: auto;
                    border-color: #3a475c;

                    th,
                    td {
                        padding: 14px 20px;
                    }

                    th {
                        width: 124px;
                        text-align: left;
                        color: #73849e;
                        padding-left: 20px;
                    }

                    td {
                        p.label {
                            display: none;
                        }
                    }
                }
            }

            &.long_table {
                thead {
                    th {
                        white-space: nowrap;
                    }
                }

                .long_td {
                    span {
                        display: inline-block;
                        max-width: 400px;
                        padding-left: 10px;
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .table_title {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 16px;
        line-height: 24px;

        a,
        button {
            float: right;
            display: inline-block;
            text-align: center;
            min-width: 87px;
            font-size: 12px;
            background-color: #73829f;
            border-radius: 4px;
            line-height: 24px;

            span {
                color: inherit;
                font: inherit;
                vertical-align: middle;
            }

            &.background_none {
                background-color: transparent;
                color: #a1b6da;
            }
        }

        a {
            // float: right;
            // color: #a1b6da;
            // font-size: 14px;
            // font-weight: 500;
            // line-height: 24px;

            img {
                width: 7px;
                margin-left: 6px;
            }

            // padding-right: 15px;
            // background: url('/src/static/img/icons/icon_location.svg');
        }
    }

    .table_total_count {
        @include font(14, m);
        color: var(--sub);
        padding: 18px 20px;
    }
}

@media screen and (max-width: 1440px) {
    .card_table_wrapper {
        .card_table_wrap {
            table.card_table {
                //테이블 컨텐츠가 많을때, className='big'
                &.big {
                    min-width: 1440px;
                }
            }
        }

        &.padding_b {
            padding-bottom: 65px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .card_table_wrapper {
        .card_table_wrap {
            table.card_table {
                min-width: 1024px;

                &.big {
                    min-width: 1440px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .card_table_wrapper {
        .card_table_wrap {
            padding: 0 15px;

            table.card_table {
                min-width: 768px;

                &.big {
                    min-width: 1440px;
                }

                &.long_table {
                    .long_td {
                        span {
                            max-width: 200px;
                        }
                    }
                }
            }
        }

        &.padding_b {
            padding-bottom: 118px;
        }
    }
}

/*===========================================================================
	라벨 아이템들
============================================================================*/
.label_item {
    > label {
        display: block;
        margin-bottom: 6px;

        span {
            @include font(16, m);
            color: var(--sub);

            &.required:after {
                content: '*';
                display: inline-block;
                @include font(16, m);
                color: var(--yellow);
            }

            i {
                @include font(13, m);
                color: var(--sub);
            }
        }
    }

    > p {
        @include font(13, m);
        margin-top: 10px;
        color: var(--sub);
    }

    //체크박스 아이템일때 <LabelCheckBox/>
    &.label_checkbox {
        width: 100%;

        .checkbox_group {
            height: 40px;
            display: flex;
            // justify-content: space-between;
            align-items: center;

            > div {
                width: 33.333%;
            }
        }
    }

    // 다중 input /select <LabelMultiInput/>
    &.label_multi_input {
        .input_group {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
                flex: 0.45;
            }

            span {
                flex: 0.1;
                text-align: center;
                color: var(--sub);
            }
        }
    }

    // 리스트 추가  <LabelAddList/>
    &.label_add_list {
        .add_list_group {
            > div {
                margin-bottom: 12px;
            }
        }

        .add_btn {
            width: 74px;

            button {
                height: 36px;
                color: var(--sub);
            }
        }
    }

    &.label_file_input {
        .file_input_group {
            > div {
                margin-bottom: 12px;
            }
        }

        .add_btn {
            width: 74px;

            button {
                height: 36px;
                color: var(--sub);
            }
        }
    }

    // IP 입력 <LabelIPInput/>
    &.label_ip_input {
        .ip_input {
            max-height: 40px;
        }
    }
}

/*===========================================================================
	DatePicker
============================================================================*/

$transition: 0.21s;
$background: #2d3748;
$focusedBorderColor: #5ddffe;
$defaultValueColor: #73829f;
$hasValueColor: #fff;
$pickerBorderColor: #73839e;
$borderRadius: 8px;
$proximity: calc(100% + 8px);
$pieceSize: 32px;
$calendarDisabledColor: #3a475c;
$calendarTodayBackgroundColor: #92cf51;
$calendarActiveBackgroundColor: #00b0f0;

.ev-dp {
    position: relative;
    cursor: pointer;

    width: 100%;
    min-height: 40px;
    max-height: 40px;
    padding: 0 14px 0 17px;
    border-radius: $borderRadius;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $background;
    border: 1px solid $background;

    transition: $transition;

    * {
        transition: $transition;
    }

    &.focused {
        border-color: #5ddffe;
    }

    > p {
        color: $defaultValueColor;
        user-select: none;

        &.value {
            color: $hasValueColor;
        }
    }

    > img {
        user-select: none;

        &.value {
            filter: brightness(10);
        }
    }

    > section {
        position: absolute;
        right: 0;
        opacity: 0;
        //transform: translateY(5px);
        pointer-events: none;
        background-color: $background;
        border: 1px solid $pickerBorderColor;
        border-radius: $borderRadius;
        padding: 16px;
        z-index: 5;
        width: 256px;
        cursor: default;

        bottom: -5px;
        transform: translateY(100%);

        &._left {
            right: initial;
            left: 0;
        }

        &._right_top {
            transform: translateY(-15%);
        }

        &.top {
            bottom: $proximity;
        }

        &.bottom {
            top: $proximity;
        }

        &.appear {
            opacity: 1;
            //transform: translateY(0);
            pointer-events: all;
        }

        > .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 13px;

            > h6 {
                color: $defaultValueColor;
                font-size: 16px;
                font-weight: 500;
                user-select: none;
            }

            > div {
                button {
                    img {
                        user-select: none;
                        transition: 0.21s;
                    }

                    &:first-child {
                        margin-right: 20px;
                    }

                    &:disabled {
                        cursor: not-allowed;

                        img {
                            opacity: 0.32;
                        }
                    }
                }
            }
        }

        .piece {
            color: $defaultValueColor;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            height: $pieceSize;
            line-height: $pieceSize;
            user-select: none;
        }

        > .week {
            display: flex;
            width: calc(32px * 7);

            > div {
                flex: 1;
                color: $defaultValueColor;
            }
        }

        > .calendar {
            display: flex;
            flex-wrap: wrap;
            width: calc(32px * 7);

            > div {
                width: 32px;
                color: $calendarDisabledColor;
                border-radius: 50%;

                &.c {
                    color: $hasValueColor;
                    cursor: pointer;
                }

                &.t {
                    background-color: $calendarTodayBackgroundColor;
                }

                &.a {
                    background-color: $calendarActiveBackgroundColor;
                }
            }
        }
    }

    .mo_datepicker_dim {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2000;
    }
}

@media screen and (max-width: 768px) {
    .ev-dp {
        .mo_datepicker_dim {
            display: block;
        }

        > section {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            top: initial;
            width: 100vw;
            border-radius: 8px 8px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 2001;

            transform: translateY(0);

            &.bottom {
                top: initial;
            }

            &.top {
                bottom: 0;
            }

            .top {
                width: 100%;
            }

            .week {
                width: calc(10vw * 7);
            }

            .calendar {
                width: calc(10vw * 7);

                .piece {
                    width: 10vw;
                    height: 10vw;
                    line-height: 10vw;
                }
            }

            .piece {
                font-size: 16px;
            }
        }
    }
}

@keyframes popupParent {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: rgba($color: #000, $alpha: 0.65);
    }
}

@keyframes popupInner {
    0% {
        opacity: 0;
        transform: translate(-50%, calc(-50% + 10px));
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.popup-parent {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    animation: popupParent 0.21s forwards;
    z-index: 9999;

    .popup-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, calc(-50% + 20px));
        animation: popupInner 0.21s forwards;
    }
}

// ck Editor스타일, textarea에 스타일 적용/
.text_group {
    .textarea_wrapper {
        > div {
            overflow: auto;

            //볼드
            strong {
                font-weight: bold;

                & > * {
                    font-weight: inherit;
                }
            }

            //이탤릭
            em {
                font-style: italic;

                & > * {
                    font-style: inherit;
                }
            }

            //line-through
            s {
                text-decoration: line-through;

                & > * {
                    text-decoration: inherit;
                }
            }

            ol {
                list-style: decimal;

                li {
                    list-style: decimal;
                }
            }

            ul {
                list-style: disc;

                li {
                    list-style: disc;
                }
            }

            hr {
                display: block;
                unicode-bidi: isolate;
                margin-block-start: 0.5em;
                margin-block-end: 0.5em;
                margin-inline-start: auto;
                margin-inline-end: auto;
                overflow: hidden;
                border-style: inset;
                border-width: 1px;
                color: rgb(255, 255, 255);
            }

            blockquote {
                margin-inline-start: 40px;
                margin-inline-end: 40px;
            }

            h1 {
                font-weight: bold;
                font-size: 2em;
            }

            h2 {
                font-size: 1.5em;
                font-weight: bold;
            }

            h3 {
                font-size: 1.17em;
                font-weight: bold;
            }

            pre {
                white-space: pre;
            }

            table {
                border: 1px solid #fff;

                tbody {
                    border: 1px solid #fff;

                    td {
                        padding: 8px 12px;
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }
}

.flexCol {
    display: flex;
    flex-direction: column;
}

/*===========================================================================
	Layout
============================================================================*/
.half_form {
    &::after {
        content: '';
        display: block;
        clear: both;
    }

    & > .input_text,
    & > .select_box,
    & > .search_select_box,
    .address_search {
        width: calc(50% - 4px);
        float: left;
    }

    & > .input_text:nth-child(2),
    & > .select_box:nth-child(2) {
        margin-left: 8px;
        float: right;
    }
}

.address_search {
    margin-bottom: 10px;

    & > * {
        display: inline-block;
        vertical-align: middle;
    }

    button {
        width: 74px;
        height: 32px;
        border-radius: 8px;
        background-color: #00b0f0;
        color: #fff;
        font-size: 14px;
        margin-left: 8px;
    }

    .input_text {
        width: calc(100% - 82px);
    }
}

.address_input {
    &.input_text {
        .input_wrapper {
            border-color: #222a38;

            input {
                background-color: #222a38;
                color: #475671;
            }
        }
    }
}

.layout_box {
    width: 100%;
    min-height: calc(100vh - 294px);

    & > .inner {
        max-width: 1000px;
        margin: 15px auto 55px;
        height: calc(100% - 70px);
        background-color: #27303f;
        border-radius: 8px;
        padding: 20px 20px 100px;

        &.bottom_short {
            padding-bottom: 30px;
        }

        .title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 34px;
        }

        .tab_pager {
            display: flex;
            border-bottom: 1px solid #323b4f;
            margin-bottom: 44px;

            li {
                min-width: 147px;
                border-radius: 8px 8px 0 0;
                border: 1px solid #323b4f;
                border-bottom: none;
                overflow: hidden;

                &.on {
                    a {
                        background-color: #2e3848;
                        color: #fff;
                    }
                }

                &:not(:first-child) {
                    margin-left: 12px;
                }

                a {
                    display: block;
                    text-align: center;
                    line-height: 34px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #a1b6da;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .half_form {
        & > .input_text,
        & > .select_box,
        .address_search {
            width: 100%;
            float: none;
        }

        & > .input_text:nth-child(2),
        & > .select_box:nth-child(2) {
            margin-left: 0;
            margin-top: 12px;
            float: none;
        }
    }

    .mypage_form {
        .card_table_wrapper {
            .card_table_wrap {
                table {
                    &.th_block {
                        th {
                            display: table-cell !important;
                        }
                    }

                    &.card_table {
                        &.detail_table {
                            min-width: auto;
                        }

                        tr {
                            th {
                                display: none;
                            }

                            td {
                                padding: 20px 16px !important;

                                p.label {
                                    display: block;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #73849e;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btn {
        &.mini {
            width: 100%;
            height: 44px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .half_table {
        &.devide {
            &::before {
                width: calc(100% + 30px);
                height: 4px;
                left: -15px;
            }
        }

        .card_table_wrapper {
            float: none;
            width: 100%;
            position: relative;

            .card_table_wrap {
                table {
                    &.card_table {
                        min-width: auto;
                    }
                }
            }

            &:last-child {
                &::after {
                    content: '';
                    display: block;
                    width: calc(100% + 30px);
                    height: 4px;
                    position: absolute;
                    left: -15px;
                    top: 0;
                    background-color: #2c3949;
                }

                margin-top: 40px;
                padding-top: 40px;
            }
        }
    }

    .layout_box {
        & > .inner {
            padding-bottom: 40px;
            margin: 0;

            .title {
                font-weight: 500;
                margin-bottom: 30px;
            }

            .tab_pager {
                width: calc(100% + 30px);
                margin-bottom: 30px;
                margin-left: -15px;
                padding: 0 15px;

                li {
                    min-width: auto;
                    width: calc(33.33% - 6px);

                    a {
                        line-height: 39px;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    #mypage {
        .layout_box {
            & > .inner {
                border-radius: 8px 8px 0 0;
            }
        }
    }
}

.pr-20px {
    padding-right: 20px;
}

.charge_chk {
    margin-bottom: 28px;

    & > div {
        margin-right: 40px;
    }
}

.member_reg_card {
    .list {
        .join_form {
            margin-bottom: 28px;

            & > li {
                // height: 70px;
                &:not(:last-child) {
                    margin-bottom: 28px;
                }

                p {
                    margin-bottom: 6px;

                    b {
                        color: var(--yellow);
                    }
                }

                .item {
                    label {
                        display: none;
                    }
                }
            }
        }
    }
}

.card_thumbnail {
    width: 100%;
    border-radius: 8px;
    background-color: var(--sub);
    margin-top: 13px;
    text-align: center;

    &.small_card_thumbnail {
        width: 240px;
    }

    & > img {
        width: 100%;
        min-height: 120px;
    }
}

@media print {
    html,
    body {
        /*min-width:initial; */
        background-color: #1d1e28;
        -webkit-print-color-adjust: exact !important;
        min-width: 1920px;
    }
}
