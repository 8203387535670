@import '../../mixin';

#pc_header {
    height: 126px;

    &.only_top {
        height: 68px;
    }

    .header_contents {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 68px;
            padding: 0 30px;
            background: #3a475c;

            .dashboard_title {
                width: 300px;
                font-size: 30px;
                user-select: none;
            }

            .timer {
                flex: 1;
                text-align: center;
                font-size: 20px;
                color: #7281a0;
                user-select: none;
            }

            .user {
                width: 300px;

                display: flex;
                justify-content: flex-end;
                align-items: center;

                .user_info {
                    padding-right: 6px;
                    user-select: none;

                    .name {
                        font-size: 15px;
                        color: var(--title);
                        text-align: right;
                        margin-bottom: 3px;
                    }

                    .position {
                        font-size: 13px;
                        text-align: right;
                    }
                }

                .profile {
                    position: relative;
                    // display: inline-block;
                    // vertical-align: middle;
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    background: #2d3748;
                    border: 2px solid #29303f;
                    box-sizing: border-box;
                    cursor: pointer;

                    > img {
                        width: 100%;
                        height: 100%;
                    }

                    .drop_down {
                        min-width: 120px;
                        position: absolute;
                        top: 50px;
                        right: 0;
                        background-color: #29303f;
                        border-radius: 8px;
                        padding: 4px 0;
                        border: 1px solid #475871;
                        overflow: hidden;
                        z-index: 100;

                        > div {
                            display: flex;
                            align-items: center;

                            a,
                            button.logout {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 30px;
                                height: 100%;
                                padding: 5px 10px;

                                &:hover {
                                    background-color: #73839e;
                                }
                            }

                            a span,
                            span {
                                font-size: 13px;
                                line-height: 12px;
                            }

                            img {
                                width: 18px;
                                // height: 12px;
                                margin-right: 8px;
                            }
                        }

                        .business_info {
                            padding-bottom: 4px;
                        }

                        > .logout {
                            padding-top: 4px;
                            border-top: 1px solid #475871;
                        }
                    }
                }
            }
        }

        .bottom {
            width: 100%;
            height: 58px;
            background: #2e3b4e;
            padding: 0 30px;

            ul.main_menu {
                display: flex;
                align-items: center;
                height: 100%;

                > li {
                    position: relative;

                    cursor: pointer;

                    &:not(:last-of-type) {
                        margin-right: 80px;
                    }

                    > a span,
                    span {
                        font-size: 18px;
                        color: var(--title);
                        vertical-align: middle;
                        user-select: none;

                        &.on {
                            color: #fff;
                        }

                        &.active {
                            color: var(--skyBlue);
                        }
                    }

                    > img {
                        padding-left: 6px;
                        user-select: none;
                        transition: 0.21s;

                        &.active {
                            transform: rotate(-180deg);
                            padding-left: 0px;
                            padding-right: 6px;
                        }
                    }

                    .sub_menu {
                        position: absolute;
                        top: 30px;
                        left: 0;
                        min-width: 160px;
                        background-color: #29303f;
                        border-radius: 8px;
                        padding: 8px 0;
                        border: 1px solid #475871;

                        > a {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            transition: 0.21s;
                            padding: 10px 8px;

                            &.on {
                                background-color: #3a485c;

                                span {
                                    color: #fff;
                                }
                            }

                            &.active {
                                background: initial;

                                span {
                                    color: var(--skyBlue);
                                }
                            }

                            span {
                                font-size: 16px;
                                transition: 0.21s;
                            }

                            img {
                                width: 10px;
                                height: 5px;

                                &.active {
                                    transform: rotate(-180deg);
                                }
                            }

                            &:hover {
                                background-color: #73839e;

                                span {
                                    color: var(--skyBlue);
                                }
                            }
                        }

                        .last_menu {
                            a {
                                display: inline-block;
                                width: 100%;
                                padding: 6px 8px;
                                background-color: #334052;

                                &:first-child {
                                    padding: 12px 8px 6px;
                                }

                                &:last-child {
                                    padding: 6px 8px 12px;
                                }

                                img {
                                    width: 4px;
                                    height: 4px;
                                    margin-right: 4px;
                                }

                                span {
                                    color: #fff;
                                    font-size: 15px;

                                    &:hover {
                                        color: var(--skyBlue);
                                    }

                                    &.active {
                                        color: var(--skyBlue);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    #pc_header {
        height: 118px;

        .header_contents {
            .top {
                height: 60px;

                .dashboard_title {
                    width: 220px;
                    font-size: 22px;
                }

                .user {
                    width: 240px;
                }
            }
        }
    }
}
