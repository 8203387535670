@import '../../mixin';
#mo_header {
    height: 48px;
    .header_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 48px;
        background-color: #3a475c;
        padding: 0 16px;
        z-index: 1000;
        .menu {
            display: flex;
            align-items: center;
            height: 100%;
            img {
                width: 22px;
                height: 22px;
            }
        }
        .logo {
            height: 100%;
            a {
                height: 100%;
                span {
                    display: inline-block;
                    height: 100%;
                    line-height: 48px;
                    font-size: 16px;
                    @include font(16, m);
                }
            }
        }
        .profile {
            width: 30px;
            height: 30px;
            border: 2px solid #29303f;
            border-radius: 50%;
            background: #2d3748;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
            .drop_down {
                min-width: 120px;
                position: absolute;
                top: 40px;
                right: 15px;
                background-color: #29303f;
                border-radius: 8px;
                padding: 4px 0;
                border: 1px solid #475871;
                overflow: hidden;
                z-index: 100;
                > div {
                    display: flex;
                    align-items: center;
                    a,
                    button.logout {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 30px;
                        height: 100%;
                        padding: 5px 10px;
                        &:hover {
                            background-color: #73839e;
                        }
                    }
                    a span,
                    span {
                        font-size: 13px;
                        line-height: 12px;
                    }
                    img {
                        width: 18px;
                        // height: 12px;
                        margin-right: 8px;
                    }
                }
                .business_info {
                    padding-bottom: 4px;
                }
                > .logout {
                    padding-top: 4px;
                    border-top: 1px solid #475871;
                }
            }
        }
        #mo_sidebar {
            position: fixed;
            top: 0;
            left: -100%;
            bottom: 0;
            width: 100vw;
            background: var(--cardBackground);
            transition: left 0.21s;
            padding: 0 16px;
            overflow-y: auto;
            overflow-x: hidden;
            &.active {
                left: 0;
            }

            .close {
                display: flex;
                align-items: center;
                height: 48px;
                margin-bottom: 42px;

                img {
                    width: 22px;
                    height: 22px;
                    padding: 2px;
                }
            }

            .menu_list {
                .menu_item {
                    .menu_title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 25px;
                        > a span,
                        > span {
                            color: #a1b6da;
                            &.on {
                                color: #fff;
                            }
                            &.active {
                                color: var(--skyBlue);
                            }
                        }
                        > span {
                            font-size: 16px;
                            font-weight: 500;
                        }
                        > img {
                            width: 14px;
                            height: 7px;
                        }
                    }

                    .mo_submenu {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 24px;
                        a {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background-color: #3a485c;
                            padding: 11px 12px;
                            border-radius: 8px;
                            margin-bottom: 10px;
                            span {
                                font-size: 15px;
                                font-weight: 500;
                                &.active {
                                    color: var(--skyBlue);
                                }
                            }
                            img {
                                width: 12px;
                                height: 6px;
                            }
                        }
                        .mo_lastmenu {
                            background-color: #334052;
                            margin-top: -4px;
                            margin-bottom: 10px;
                            border-radius: 8px;
                            a {
                                justify-content: initial;
                                background-color: initial;
                                &:not(:last-of-type) {
                                    margin-bottom: 0px;
                                }
                                span {
                                    &.active {
                                        color: var(--skyBlue);
                                    }
                                }
                                img {
                                    width: 4px;
                                    height: 4px;
                                    margin-right: 6px;
                                }
                            }
                        }
                    }

                    .mo_lastmenu {
                        a {
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
