//모바일에서만 dim 생성
.select_box {
    width: 100%;
    .search_select_dim {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
    }
    .search_select {
        width: 100%;
        position: relative;
        user-select: none;
        .selected {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            height: 40px;

            background-color: #2d3748;
            border: 1px solid var(--cardBorder);
            border-radius: 8px;
            box-sizing: border-box;
            padding: 8px 18px;
            color: var(--sub);
            cursor: pointer;
            transition: 0.21s;
            span {
                &:first-of-type {
                    color: var(--skyBlue);
                }
                &:last-of-type {
                    color: #fff;
                    margin-left: 10px;
                }
            }

            &.focus {
                border: 1px solid #5ddffe;
                // color: #fff;
                img {
                    transform: rotate(-180deg);
                }
            }
            &.isSelected {
                color: #fff;
            }
            img {
                position: absolute;
                top: 42%;
                right: 10px;
                transition: 0.21s;
            }
        }

        .options {
            position: absolute;
            top: 45px;
            left: 0;
            width: 100%;
            background-color: var(--cardBackground);
            border: 1px solid var(--selectBorder);
            border-radius: 8px;
            box-sizing: border-box;
            padding: 8px 0;
            z-index: 100;
            &.rise {
                top: initial;
                bottom: 45px;
            }
            .options_search {
                padding: 8px;
                input[type='text'] {
                    width: 100%;
                    height: 36px;
                    font-size: 16px;
                    color: #fff;
                    background-color: #2d3749;
                    border: 1px solid var(--selectBorder);
                    border-radius: 8px;
                    box-sizing: border-box;
                    padding: 0 8px;
                    &:focus {
                        border: 1px solid #5ddffe;
                    }
                }
            }
            .options_list {
                max-height: 160px;
                overflow-x: hidden;
                overflow-y: auto;
                div {
                    padding: 12px 10px;
                    color: #a5b7da;
                    cursor: pointer;
                    &:hover {
                        background-color: #3a485c;
                    }
                    span {
                        &:first-of-type {
                            color: var(--skyBlue);
                        }
                        &:last-of-type {
                            color: #fff;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .select_box {
        .search_select .options {
            .options_list {
                max-height: 115px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .select_box {
        .search_select .options {
            position: fixed;
            top: initial;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 8px 8px 0 0;
            max-height: initial;
            z-index: 1000;
            &.rise {
                bottom: 0;
            }
            .options_list {
                max-height: 30vh;
            }
        }
    }
}
