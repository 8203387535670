$button-size: 32px;

$l-back-color: #fff;
$l-border-color: rgb(230, 230, 230);
$l-text-color: #333;
$l-inactive-text-color: rgb(185, 185, 185);

$d-back-color: #2a2c32;
$d-border-color: rgb(123, 123, 123);
$d-text-color: #fff;
$d-inactive-text-color: rgb(134, 134, 134);

#__recoil_dev_tools__ {
    position: fixed;
    right: 100px;
    bottom: 100px;
    z-index: 9999999999;

    @media screen and (max-width: 768px) {
        display: none;
    }

    * {
        transition: 0.21s;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    // Float Button Style
    > button {
        width: $button-size;
        height: $button-size;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 12px rgba($color: #6a6868, $alpha: 0.4);
        overflow: hidden;
        background-color: $l-back-color;

        img {
            width: 70%;
            height: 70%;
        }
    }

    // Layer Popup Style
    > div {
        position: absolute;
        bottom: 100%;
        right: 0;
        padding-top: 24px;
        opacity: 0;
        pointer-events: none;
        border-radius: 8px;
        background-color: $l-back-color;
        box-shadow: 0 9px 18px rgba($color: #696969, $alpha: 0.3);

        // Layer Popup When Show Style
        &.s {
            opacity: 1;
            pointer-events: all;
            transform: translateY(-10px);
        }

        // Top Nav Tab Style
        > nav {
            display: flex;
            border-bottom: 1px solid $l-border-color;
            padding: 0 12px;
            position: relative;

            // Tab Button Style
            > button {
                color: $l-inactive-text-color;
                padding-bottom: 8px;
                position: relative;
                width: 74px;
                text-align: center;

                // Tab Button Bottom Border After Style
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1px;
                    height: 2px;
                    background-color: $l-text-color;
                    opacity: 0;
                    transition: 0.21s;
                }

                // Tab Button Active Style
                &.a {
                    color: $l-text-color;
                    &::after {
                        opacity: 1;
                    }
                }
            }

            // Theme Toggle Style
            > div.toggle {
                position: absolute;
                right: 12px;
                top: -10px;
                height: 24px;
                border-radius: 12px;
                width: 40px;
                cursor: pointer;
                background-color: $d-back-color;

                > div {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 19px;
                    left: 3px;
                    width: 19px;
                    background-color: $l-back-color;
                    border-radius: 50%;
                }
            }

            > div.reset {
                position: absolute;
                top: -10px;
                height: 24px;
                width: 24px;
                right: 72px;
                background-color: $d-back-color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.h {
                    transform: translateY(3px);
                    pointer-events: none;
                    opacity: 0;
                }

                img {
                    width: 70%;
                    height: 70%;
                    filter: invert(1);
                    transform: translateY(-1px);
                }
            }
        }

        // Bottom Area Style
        article {
            display: flex;
            padding: 0 12px;

            // Bottom Left Aside Style
            aside {
                padding: 10px 0 24px;
                width: 200px;
                height: 400px;
                overflow-y: auto;
                overflow-x: hidden;
                border-right: 1px solid $l-border-color;
                padding-right: 12px;
                display: flex;
                flex-direction: column;

                // Aside Item Style
                button {
                    color: $l-inactive-text-color;
                    padding: 5px 5px;
                    max-width: 100%;
                    word-break: break-all;

                    // Aside Item Focus Style
                    &.f {
                        color: $l-text-color;
                    }
                }
            }

            // Bottom Right Content Section Style
            section {
                padding: 10px 0 24px;
                height: 400px;
                width: 500px;
                padding-left: 14px;
                overflow-y: auto;

                // Atom or Selector Item Style
                > ul > li {
                    color: $l-text-color;
                    padding: 5px;
                    margin-bottom: 12px;
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    > div {
                        color: inherit;
                        display: flex;
                        position: relative;
                        > h3 {
                            color: inherit;
                            font-size: 1.05rem;
                            display: inline-block;
                            margin-right: 6px;
                            font-weight: 600;
                        }
                        > div {
                            pointer-events: none;
                            &.v {
                                width: 16px;
                                height: 16px;
                                margin-right: 10px;
                                border-radius: 50%;
                                color: #fff;
                                background-color: rgb(80, 63, 235);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 10px;
                                margin-bottom: 2px;
                            }
                            &.he,
                            &.hv,
                            &.l {
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);

                                &.he {
                                    background-color: rgb(189, 38, 38);
                                }
                                &.hv {
                                    background-color: rgb(22, 155, 29);
                                }
                                &.l {
                                    background-color: rgb(167, 103, 19);
                                }
                            }
                        }
                        > span {
                            color: $l-inactive-text-color;
                            font-size: 0.95rem;
                        }

                        &:first-child {
                            margin-bottom: 8px;
                            align-items: baseline;
                        }
                        &:nth-child(2) {
                            align-items: center;
                        }
                    }

                    ul.detail-ul {
                        display: flex;
                        flex-direction: column;
                        margin-top: 6px;

                        &.mt-zero {
                            margin-top: 0;
                        }

                        > li {
                            display: flex;
                            flex-direction: column;

                            > div {
                                display: flex;
                                align-items: center;
                                height: 24px;

                                span {
                                    display: inline-block;
                                    &.key {
                                        white-space: nowrap;
                                        color: #777;
                                        margin-right: 6px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            p {
                color: inherit;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                letter-spacing: 0.75px;
                height: 20px;
                transition: unset;

                &.ca {
                    cursor: pointer;
                }

                &.str {
                    color: rgb(12, 175, 20);
                }
                &.num,
                &.bool {
                    color: rgb(100, 49, 219);
                }
                &.null,
                &.und {
                    color: #777;
                }
                &.func {
                    color: $l-text-color;
                }
                &.arr,
                &.obj {
                    color: $l-text-color;
                    span {
                        letter-spacing: inherit;
                        line-height: inherit;
                        &.str {
                            color: rgb(12, 175, 20);
                        }
                        &.num,
                        &.bool {
                            color: rgb(100, 49, 219);
                        }
                        &.null,
                        &.und,
                        &.key {
                            color: #777;
                        }
                        &.arr,
                        &.obj,
                        &.func {
                            color: $l-text-color;
                        }
                    }
                }
            }
        }
    }

    // Dark Theme Style
    &.dark {
        > button {
            background-color: $d-back-color;
        }
        > div {
            background-color: $d-back-color;
            > nav {
                border-bottom: 1px solid $d-border-color;
                > button {
                    color: $d-inactive-text-color;
                    &::after {
                        background-color: $d-text-color;
                    }
                    &.a {
                        color: $d-text-color;
                    }
                }
                > div.toggle {
                    background-color: $l-back-color;
                    > div {
                        background-color: $d-back-color;
                        left: 18px;
                    }
                }
                > div.reset {
                    background-color: $l-back-color;
                    > img {
                        filter: invert(0);
                    }
                }
            }
            article {
                aside {
                    border-right: 1px solid $d-border-color;
                    button {
                        color: $d-inactive-text-color;
                        &.f {
                            color: $d-text-color;
                        }
                    }
                }
                section {
                    li {
                        color: $d-text-color;
                        > div {
                            > span {
                                color: #999 !important;
                            }
                        }
                    }
                }
                p {
                    &.str {
                        color: rgb(73, 206, 80);
                    }
                    &.num,
                    &.bool {
                        color: rgb(145, 107, 231);
                    }
                    &.null,
                    &.und {
                        color: #999;
                    }
                    &.func {
                        color: $d-text-color;
                    }
                    &.arr,
                    &.obj {
                        color: $d-text-color;
                        span {
                            &.str {
                                color: rgb(73, 206, 80);
                            }
                            &.num,
                            &.bool {
                                color: rgb(145, 107, 231);
                            }
                            &.null,
                            &.und,
                            &.key {
                                color: #999;
                            }
                            &.arr,
                            &.obj,
                            &.func {
                                color: $d-text-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
