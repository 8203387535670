footer {
    border-top: 1px solid #2c3949;
    padding: 27px 30px 27px;
    height: 150px;
    // height: 163px;

    div.bar {
        width: 1px;
        background-color: #475771;
    }

    div.break {
        min-width: 100%;
    }

    > .top {
        display: flex;
        align-items: center;

        span {
            color: #a1b6da;
        }

        .bar {
            margin: 0 12px;
            height: 12px;
        }

        margin-bottom: 19px;
    }

    > .middle {
        display: flex;
        flex-direction: column;

        > .row {
            display: flex;
            align-items: center;

            span {
                color: #73829f;
                font-size: 13px;
            }

            .bar {
                margin: 0 10px;
                height: 8px;
            }

            &:last-child {
                margin-top: 5px;
            }
        }
    }

    p {
        margin-top: 19px;
        color: #3a475c;
        font-weight: 300;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 15px;
        height: 242px;

        > .top {
            flex-wrap: wrap;

            .bar {
                margin: 0 10px;

                &:first-of-type {
                    background-color: transparent;
                    margin: 0;
                    height: 10px;
                    min-width: 100%;
                }
            }
        }

        > .middle {
            .row {
                flex-wrap: wrap;

                .bar {
                    background-color: transparent;
                    margin: 0;
                    height: 4px;
                    min-width: 100%;
                }

                &:last-child {
                    margin-top: 12px;
                }
            }
        }
    }
}
