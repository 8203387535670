@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

#scenario {
    background-color: #2e3949;
    min-height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    justify-content: center;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        background-color: #2d3034;

        > .left {
            display: flex;
            align-items: center;
            > .select_box {
                width: 180px;
            }

            > .params {
                width: max-content;
                display: flex;
                align-items: center;
                height: 40px;
                padding: 8px 18px;
                background-color: #2d3748;
                border-radius: 8px;
                margin-left: 16px;
                line-height: 1.2;

                > p {
                    color: rgb(230, 103, 81);
                    margin-left: 6px;
                    line-height: 16px;
                }
            }
        }

        > button {
            width: 120px;
            transition: 0.21s;

            &.skyBlue {
                &:hover {
                    background: #1480ab;
                }

                &:active {
                    background: #0070bf;
                }
            }
        }
    }

    section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    article {
        display: flex;
        flex-direction: row;
        max-width: 1024px;
        margin: auto;
    }

    .top-button-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 4px 12px;
        margin: 0 auto 16px;
        flex: 1;
        > button {
            width: max-content;
            padding: 0 8px;
            transition: 0.21s;

            &.lightGreen {
                &:hover {
                    background: #87b359;
                }

                &:active {
                    background: #72964a;
                }
            }

            &.skyBlue {
                &:hover {
                    background: #1480ab;
                }

                &:active {
                    background: #0070bf;
                }
            }
        }

        .new-line {
            width: 100%;
        }
    }

    ul {
        border: 1px solid #fff;
        border-radius: 8px;
        flex: 1;
        overflow-y: auto;
        padding: 12px 16px;
        background-color: #323a45;
        margin: 0 auto;

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
        }

        li {
            animation: fadeIn 0.32s forwards;

            > p {
                line-height: 24px;

                &.time {
                    color: #e1e2e3;
                }

                &.open {
                    color: rgb(156, 202, 255);
                }

                &.error {
                    color: #e7776f;
                }

                &.success {
                    color: #5cb85c;
                }
            }
            margin-bottom: 16px;
        }
    }
}
